.navbar8-container {
  top: 0;
  width: 100%;
  display: flex;
  opacity: 1;
  z-index: 1000;
  position: sticky;
  box-shadow: 5px 5px 10px 8px rgba(0, 0, 0, 0.59);
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
.navbar8-container1 {
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
}
.navbar8-navbar-interactive {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #ffffff;
}
.navbar8-navlink {
  display: contents;
}
.navbar8-text {
  text-decoration: none;
}
.navbar8-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar8-links {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.navbar8-link1 {
  text-decoration: none;
}
.navbar8-link3 {
  text-decoration: none;
}
.navbar8-buttons {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navbar8-action1 {
  display: flex;
  border-color: #14a232;
  animation-name: none;
  flex-direction: row;
  animation-delay: 0s;
  text-decoration: none;
  background-color: #006900;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.navbar8-burger-menu {
  display: none;
}
.navbar8-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar8-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar8-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar8-logo {
  height: 3rem;
}
.navbar8-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar8-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar8-links1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
}
.navbar8-link4-accordion {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar8-trigger {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.navbar8-icon-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar8-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar8-icon04 {
  width: 24px;
  height: 24px;
}
.navbar8-container3 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar8-icon06 {
  width: 24px;
  height: 24px;
}
.navbar8-container4 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-halfunit);
  grid-template-columns: 1fr;
}
.navbar8-menu-item {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar8-page1-image {
  width: 50px;
  height: 50px;
}
.navbar8-content {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar8-menu-item1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar8-page2-image {
  width: 50px;
  height: 50px;
}
.navbar8-content1 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar8-menu-item2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar8-page3-image {
  width: 50px;
  height: 50px;
}
.navbar8-content2 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar8-menu-item3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.navbar8-page4-image {
  width: 50px;
  height: 50px;
}
.navbar8-content3 {
  width: 340px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.navbar8-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
}
.navbar8-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.navbar8-text04 {
  display: inline-block;
}
.navbar8-text05 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.navbar8-text06 {
  display: inline-block;
  text-decoration: none;
}
.navbar8-text07 {
  color: #049016;
  display: inline-block;
  font-family: "DavidaOpti-Bold";
}
.navbar8-text08 {
  display: inline-block;
}
.navbar8-text09 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.navbar8-text10 {
  display: inline-block;
}
.navbar8-text11 {
  display: inline-block;
}
.navbar8-text12 {
  display: inline-block;
}
.navbar8-text13 {
  display: inline-block;
}
.navbar8-text14 {
  display: inline-block;
  text-decoration: none;
}
.navbar8-text15 {
  display: inline-block;
}
.navbar8-text16 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.navbar8-text17 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.navbar8-text18 {
  display: inline-block;
}
.navbar8-root-class-name {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  background-color: #ffffff;
}
@media(max-width: 767px) {
  .navbar8-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar8-desktop-menu {
    display: none;
  }
  .navbar8-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar8-mobile-menu {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .navbar8-container4 {
    grid-gap: 0;
  }
  .navbar8-text07 {
    margin-right: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .navbar8-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navbar8-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
