.logos2-container {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.logos2-max-width {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.logos2-logo1 {
  object-fit: contain;
  animation-name: none;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.logos2-logo2 {
  object-fit: contain;
  animation-name: none;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.logos2-logo3 {
  object-fit: contain;
  animation-name: none;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.logos2-logo4 {
  object-fit: contain;
  animation-name: none;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.logos2-logo5 {
  object-fit: contain;
  animation-name: none;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.logos2-text1 {
  width: 45%;
  display: inline-block;
  text-align: center;
}
.logos2-root-class-name {
  width: 100%;
  height: 100%;
}
@media(max-width: 991px) {
  .logos2-text1 {
    width: 100%;
    text-align: center;
  }
}
@media(max-width: 767px) {
  .logos2-container {
    gap: var(--dl-space-space-twounits);
  }
  .logos2-max-width {
    gap: var(--dl-space-space-twounits);
  }
}
